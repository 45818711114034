@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "normalize.css/normalize.css";

:root {
    --color-white: #fff;
    --color-black: #000;
    --color-green: #1cfeba;
    --color-yellow: #ffd100;
    --color-grey: #bfbfbf;
    --color-grey-dark: #949494;
    --color-red: red;
    --color-black-dark-shade: #39738b;
    --color-black-light-shade: #1cfeba;
    --color-discord: #7289da;
    --color-instagram: #833ab4;
    --color-twitter: #1da1f2;
    --font-size-header-1: 32px;
    --font-size-tiny: 13px;
    --header-height: 100px;
    --header-mobile-height: 160px;
    --spacing: 4px;
    --spacing-half: 2px;
    --spacing-2x: 8px;
    --spacing-3x: 12px;
    --spacing-4x: 16px;
    --spacing-5x: 20px;
    --spacing-6x: 24px;
    --spacing-7x: 28px;
    --spacing-8x: 32px;
    --spacing-10x: 40px;
    --spacing-12x: 48px;
    --spacing-14x: 56px;
    --spacing-15x: 60px;
    --spacing-16x: 64px;
    --spacing-20x: 80px;
}

* {
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    display: none;
}

body {
    background: var(--color-black);
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: var(--color-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: var(--color-green);
    transition: all 250ms ease;
}

a:hover {
    color: var(--color-white);
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

ul {
    margin: 0;
    padding: 0;
}

.Minter {
    margin: 0 auto;
    text-align: left;
    width: 100%;
}

input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    font-size: 16px;
    width: 100%;
    border-color: rgba(4, 4, 5, 0.1);
    line-height: 32px;
}

input:focus {
    outline: none;
    border-color: rgba(4, 4, 5, 0.4);
}

button {
    /* background: var(--color-black-light-shade); */
    cursor: pointer;
    padding: 9px 16px;
    max-height: 120px;
    border: 4px solid var(--color-white);
    color: var(--color-black);
    font-family: "Montserrat", sans-serif;
    border-radius: 0px;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    outline: none;
    transition: all 250ms ease;
}

button:hover {
    background: var(--color-white);
    box-shadow: none;
}

.uploadFile {
    border: 4px dashed rgba(4, 4, 5, 0.1);
    border-radius: 16px;
    text-align: center;
    padding: 32px 60px;
}

/* Claiming */

.ClaimingContent {
    min-height: 1035px;
}

/* Not Claiming */

.NotClaimingContent {
    min-height: 1035px;
}

.Section.Mint {
    border-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}

/* Sold Out Claiming */

.SoldOut {
    align-items: center;
    justify-content: center;
    min-height: 1035px;
    text-align: center;
    width: 100%;
}

.SoldOut .sold-out {
    text-align: center;
    width: 100%;
}

.SoldOut a {
    font-size: 1em;
    text-align: center;
    width: 100%;
}

.SoldOut .sold-out-open-sea {
    font-size: 1em;
    text-align: center;
    width: 100%;
}

.SoldOut a:hover * {
    color: var(--color-green) !important;
}

/* Mint */

.Section.Mint {
    border-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}

.Section.Minting {
    align-items: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-height: 716px;
    text-align: center;
    max-width: 700px;
}

.Section.Minting .read-this {
    color: var(--color-green) !important;
    font-size: 2em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
}

.Section.Minting .minting-prompt {
    font-size: 1.5em;
    margin-bottom: 100px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: 100%;
}

.Section.Mint {
    border-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}

.Section.MintNotes {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}

.MintButton {
    border: 0px solid black;
    background: var(--color-green);
    color: var(--color-black) !important;
    font-size: 40px;
    letter-spacing: 6px;
    line-height: 40px;
    margin-top: 40px;
    min-height: 100px;
    padding-top: 17px;
    outline: none;
    width: 100%;
}

.MintButton p {
    color: var(--color-black);
    font-size: var(--font-size-tiny);
    letter-spacing: 0px;
    line-height: var(--font-size-tiny);
}

.MintAmount.row,
.MintSupply.row {
    align-items: center;
    justify-content: center;
    width: 100%;
}

.MintSupply {
    color: var(--color-grey-dark);
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-4x);
}

.MintPricing {
    color: var(--color-grey-dark);
    margin-top: var(--spacing-4x);
}

.MintWarning {
    font-size: 12px !important;
}

.MintAmountButton {
    background: var(--color-black) !important;
    background-color: var(--color-black) !important;
    border-radius: 50%;
    color: var(--color-white);
    font-size: 24px;
    height: 60px;
    width: 60px;
}

.ContractAddress a,
.ContractAddress:active,
.ContractAddress:visited,
.ContractAddress:focus {
    color: var(--color-white) !important;
    font-size: 14px !important;
}

@media only screen and (max-width: 400px) {
    .Section.Minting .minting-prompt {
        font-size: 24px !important;
    }

    .Section.Mint {
        padding-top: var(--spacing-6x) !important;
        padding-bottom: var(--spacing-6x) !important;
    }

    .MintSupply {
        margin-bottom: var(--spacing-2x);
        margin-top: var(--spacing-4x);
    }

    .ContractAddress a,
    .ContractAddress:active,
    .ContractAddress:visited,
    .ContractAddress:focus {
        font-size: 10px !important;
    }
}

/* Logo */

.logo {
    color: var(--color-white);
    text-decoration: none;
    transform: all 250ms ease;
}

.logo:hover {
    color: var(--color-green);
}

.row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width: 992px) {
    .row {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.inner-container {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

/* Metamask Link */

.social-link {
    color: var(--color-green);
    text-decoration: underline;
}

.link-metamask {
    color: var(--color-black);
    transition: all 250ms ease;
    text-decoration: underline;
}

.link-metamask:hover {
    color: var(--color-green);
    text-decoration: underline;
}

/* Label */

.supertitle {
    font-size: 3em;
    font-style: italic;
    font-weight: 900;
    line-height: 1em;
    margin: 0px;
    margin-bottom: var(--spacing-6x) !important;
    text-align: left;
    text-transform: uppercase;
}

.title.mint-amount {
    font-size: 5em;
    font-style: italic;
    font-weight: 900;
    line-height: 1em;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    width: 100px;
}

.title.sale-status {
    font-size: 5em;
    font-style: italic;
    font-weight: 900;
    line-height: 1em;
    margin: 0px;
    margin-bottom: var(--spacing-2x);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;
    max-width: 600px;
    width: 100%;
}

.title.sale-directions {
    color: var(--color-grey-dark);
    font-size: 1em;
    font-style: italic;
    font-weight: 900;
    line-height: 1.3em;
    margin: 0px;
    margin-bottom: var(--spacing-16x);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;
    max-width: 570px;
    width: 100%;
}

.title {
    font-size: 2em;
    font-style: italic;
    font-weight: 900;
    margin: var(--spacing-2x) 0px;
    text-align: left;
    text-transform: uppercase;
}

.subtitle {
    color: var(--color-green);
    font-size: 1.5em;
    font-style: italic;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
}

.exclamation {
    color: var(--color-black);
    font-size: 0.8em;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2em;
    text-align: left;
    text-transform: uppercase;
}

.subtext {
    color: var(--color-white);
    font-size: 1.15em;
    font-style: italic;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
}

.body-text {
    font-size: 1em;
    line-height: 1.5em;
    text-align: left;
}

.small-text {
    color: var(--color-grey);
    font-size: 0.8em;
    line-height: 1.5em;
    text-align: left;
}

.legal-text {
    font-size: 1.1em;
    line-height: 1.7em;
    font-weight: 500;
    text-align: justify;
}

.tiny-text {
    font-size: 10px;
}

@media only screen and (max-width: 992px) {
    .title {
        font-size: 1.5em;
    }

    .supertitle {
        font-size: 2.3em;
    }
    .exclamation {
        font-size: 0.6em;
        line-height: 1.2em;
    }
}

@media only screen and (max-width: 400px) {
    .title.mint-amount {
        font-size: 3.8em;
    }

    .title.sale-status {
        font-size: 2.4em;
        line-height: 1em;
        max-width: 100%;
        margin-top: 0;
        width: 100%;
    }

    .title.sale-directions {
        color: var(--color-grey-dark);
        font-size: 0.7em;
        line-height: 1.3em;
        margin-bottom: var(--spacing-6x);
        max-width: 100%;
        width: 100%;
    }
}

/* Header */

.Header {
    background-color: var(--color-black);
    height: var(--header-height);
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-5x);
    left: 0px;
    z-index: 10;
}

.Header .row {
    align-items: center;
}

.Header .list {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    list-style: none;
}

.Header .list-item {
    cursor: pointer;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    transition: all 250ms ease;
    margin-right: var(--spacing-8x);
}

.Header .list-item:hover {
    color: var(--color-green);
}

.Header .list-item.icon {
    margin-right: var(--spacing-4x);
}

.Header .list-item.icon:last-child {
    margin-right: 0px;
}

@media only screen and (max-width: 992px) {
    .Header {
        height: var(--header-mobile-height);
    }
    .Header .row {
        flex-direction: column;
    }
}

/* Footer */

.Footer {
    background: var(--color-black);
    border-top: 2px solid rgba(255, 255, 255, 1);
    margin-left: 5%;
    margin-right: 5%;
    min-height: 240px;
    padding: var(--spacing-16x) var(--spacing-16x);
}

.Footer .icons {
    justify-content: flex-end;
}

.Footer .column {
    align-items: flex-end;
}

.Footer .inner-container {
    justify-content: space-between;
}

.Footer .list {
    list-style: none;
    width: 100%;
}

.Footer .list-item {
    cursor: pointer;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    transition: all 250ms ease;
    margin-right: var(--spacing);
}

.Footer .list-item:hover {
    color: var(--color-green);
}

.Footer .list-item.icon {
    margin-right: var(--spacing-4x);
}

.Footer .list-item.icon:last-child {
    margin-right: 0px;
}

@media only screen and (max-width: 992px) {
    .Footer {
        padding: var(--spacing-16x) 0;
    }

    .Footer .row {
        width: 100%;
    }

    .Footer .contacts {
        align-items: center;
        margin-top: var(--spacing-8x);
        width: 100%;
    }

    .Footer .contacts .row,
    .Footer .contacts .column {
        align-items: center;
        justify-content: center;
        margin-left: 0;
        width: 100%;
    }

    .Footer .label .tiny-text {
        color: var(--color-green);
    }
}

/* Content */

.Content {
    min-height: calc(100vh - var(--header-height));
    padding: var(--spacing-4x) var(--spacing-4x) 0;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .Content {
        padding-top: 0px;
    }
}

/* Section */

.Section {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: var(--spacing-10x);
    padding-top: var(--spacing-10x);
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .Section {
        padding-bottom: var(--spacing-6x);
        padding-top: var(--spacing-6x);
        padding-left: var(--spacing-2x);
        padding-right: var(--spacing-2x);
    }
}

/* Banner */

.Banner {
    background: var(--color-black);
    margin-bottom: -1px;
    margin-top: var(--spacing-2x);
    overflow: scroll;
    width: 100%;
    z-index: 4;
}

.Banner img {
    border-radius: 2px;
    max-width: min(1100px, calc(100vw - 32px));
}

@media only screen and (max-width: 992px) {
    .Banner {
        margin-top: 0px;
        max-width: calc(100vw + 40px);
        margin-left: -20px;
        width: 100vw;
    }

    .Banner img {
        border-radius: 2px;
        max-width: 100vw;
    }
}

/* Strip */

.Strip {
    background: var(--color-green);
    padding: var(--spacing-half);
    text-align: center;
}

.Strip .label {
    text-align: center;
    width: 100%;
}

.Strip.ethNetwork {
    background: var(--color-red);
    width: 100%;
}

.Strip.ethNetwork * {
    color: var(--color-white);
    width: 100%l;
}

.Strip.ethNetwork.connected {
    background: var(--color-yellow);
}

.Strip.ethNetwork.connected * {
    color: var(--color-black);
}

.Strip.walletAddress {
    background: var(--color-black);
    color: var(--color-white);
}

.Strip.walletAddress * {
    color: var(--color-white);
}

.Strip.walletAddress.connected {
    background: var(--color-yellow);
}

.Strip.walletAddress.connected * {
    color: var(--color-black);
}

/* Discord Strip */

.Strip.discord {
    background: var(--color-discord);
    cursor: pointer;
}

.Strip.discord .label {
    color: var(--color-white);
}

.Strip.discord:hover .label {
    color: var(--color-black);
}

@media only screen and (max-width: 992px) {
    .Strip.discord {
        margin-bottom: var(--spacing);
    }
    .Strip.discord .label {
        font-size: 12px;
    }
}

/* One by One */

.OneByOne {
    align-items: space-between;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: min(300px, 100vw);
    margin-left: 120px;
    margin-top: var(--spacing-6x);
    margin-bottom: var(--spacing-12x);
    max-width: 400px;
}

.OneByOne .square {
    background: transparent;
    border-radius: var(--spacing-2x);
    height: auto;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .OneByOne {
        flex-basis: 100%;
        margin-left: 0px;
        min-width: 60vw;
    }

    .OneByOne .square {
        margin-left: var(--spacing);
        margin-right: var(--spacing);
    }
}

/* Four By Four */

.FourByFour {
    align-items: space-between;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: min(300px, 100vw);
    margin-left: 120px;
    margin-bottom: var(--spacing-8x);
    max-width: 400px;
}

.FourByFour .square {
    background: transparent;
    border-radius: var(--spacing-2x);
    height: auto;
    margin: var(--spacing-2x);
    width: calc(50% - var(--spacing-4x));
}

@media only screen and (max-width: 992px) {
    .FourByFour {
        margin-left: 0px;
        min-width: 60vw;
        margin-bottom: var(--spacing-16x);
    }
    .FourByFour .square {
        margin: var(--spacing);
        width: calc(50% - var(--spacing-2x));
    }
}

/* Subscribe */

.Subscribe .row {
    justify-content: flex-start;
}

.Subscribe .input-email {
    background: var(--color-white);
    border: 0px;
    border-top-left-radius: var(--spacing);
    border-bottom-left-radius: var(--spacing);
    color: var(--color-black);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    height: 40px;
    outline: none;
    padding: var(--spacing) var(--spacing-3x);
    max-width: 240px;
}

.Subscribe .button-submit {
    border-top-right-radius: var(--spacing);
    border-bottom-right-radius: var(--spacing);
    background: var(--color-white);
    display: flex;
    height: 40px;
    line-height: 40px;
    padding-right: var(--spacing);
    margin-left: -6px;
    transition: all 250ms ease;
}

.Subscribe .button-submit * {
    transition: all 250ms ease;
}

.Subscribe .button-submit:hover * {
    color: var(--color-green);
    transition: all 250ms ease;
}

@media only screen and (max-width: 992px) {
    .Subscribe .label {
        text-align: center;
    }

    .Subscribe .row {
        justify-content: center;
    }
}

/* Team Members */

.name {
    color: var(--color-green);
}

@media only screen and (max-width: 992px) {
    .team {
        margin-top: var(--spacing-12x);
    }
}

/* Loader */

.loader,
.loader:after {
    border-radius: 50%;
    width: 3em;
    height: 3em;
}
.loader {
    margin: 0px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(255, 255, 255, 0.6);
    border-right: 0.3em solid rgba(255, 255, 255, 0.6);
    border-bottom: 0.3em solid rgba(255, 255, 255, 0.6);
    border-left: 0.3em solid #000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1s infinite linear;
    animation: load8 1s infinite linear;
}

.big-loader,
.big-loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.big-loader {
    margin: 0px auto;
    font-size: 14px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(255, 255, 255, 0.6);
    border-right: 0.5em solid rgba(255, 255, 255, 0.6);
    border-bottom: 0.5em solid rgba(255, 255, 255, 0.6);
    border-left: 0.5em solid #000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1s infinite linear;
    animation: load8 1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 400px) {
    .big-loader,
    .big-loader:after {
        width: 6em;
        height: 6em;
    }
}

/* Disclaimer */

.distribution {
    flex-basis: 50%;
    margin-left: 0;
    text-align: left;
}

@media only screen and (max-width: 992px) {
    .distribution {
        flex-basis: 100%;
    }
}

.disclaimer {
    flex-basis: 50%;
    margin-left: var(--spacing-20x);
    text-align: right;
}

@media only screen and (max-width: 992px) {
    .disclaimer {
        flex-basis: 100%;
        margin-left: 0px;
        margin-right: var(--spacing-10x);
        text-align: left;
    }
}

/* Specs */

.label.specs {
    margin-top: var(--spacing-4x);
    margin-bottom: var(--spacing-8x);
}

@media only screen and (max-width: 992px) {
    .label.specs {
        margin-bottom: var(--spacing-12x);
    }
}

/* Club */

.label.club {
    margin-top: var(--spacing-4x);
    margin-bottom: var(--spacing-8x);
}

@media only screen and (max-width: 992px) {
    .label.club {
        margin-bottom: var(--spacing-6x);
    }
}

/* Roadmap */

.row.roadmap {
    margin-bottom: var(--spacing-4x);
    max-width: 75%;
}

.label.detail {
    font-size: 14px;
}

.roadmap.percent {
    color: var(--color-green);
    font-weight: bold;
    min-width: 60px;
}

.row.roadmap {
    justify-content: flex-start;
}

@media only screen and (max-width: 992px) {
    .roadmap.percent {
        margin-bottom: var(--spacing-6x);
    }

    .row.roadmap {
        margin-bottom: var(--spacing-4x);
        max-width: 100%;
    }

    .label.detail {
        font-size: 16px;
    }
}

/* Bonding */

.row.bonding {
    align-items: flex-end;
    justify-content: space-between;
}

.label.bonding {
    margin-top: var(--spacing-4x);
    margin-bottom: var(--spacing-20x);
}

@media only screen and (max-width: 992px) {
    .label.bonding {
        margin-bottom: var(--spacing-6x);
    }
}

/* Subscribe Dialog */

.DialogSubscribe {
    background: var(--color-black);
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: var(--spacing-6x);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 90vh;
    min-height: 600px;
    height: 100%;
    margin: 0px;
    max-width: min(80%, 500px);
    padding: var(--spacing-8x);
}

.DialogSubscribe .label {
    padding: var(--spacing-2x);
    text-align: justify;
}

.DialogSubscribe .title {
    padding: var(--spacing);
    text-align: center;
}

.DialogSubscribe .buttons {
    width: 100%;
}

.DialogSubscribe .buttons .row {
    margin-top: var(--spacing-2x);
    width: 100%;
}

/* Social Buttons */

.SocialButton {
    border: 0px;
    border-radius: 6px;
    height: 50px;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
    transition: all 150ms ease;
}

.SocialButton {
    align-items: center;
    background: var(--color-white);
    display: flex;
    font-size: 14px;
    justify-content: center;
    transition: all 250m / s ease;
}

.SocialButton:hover {
    color: white;
}

.SocialButton svg {
    margin-left: var(--spacing);
}

.SocialButton.discord {
    width: calc(100% - var(--spacing-2x));
}

.SocialButton.discord:hover {
    background: #7289da;
}

.SocialButton.instagram:hover {
    background-image: linear-gradient(
        to right,
        #405de6,
        #5851db,
        #c13584,
        #e1306c,
        #fd1d1d,
        #f56040,
        #f77737,
        #fcaf45,
        #ffdc80
    );
}

.SocialButton.twitter:hover {
    background: var(--color-twitter);
}

.SocialButton.instagram,
.SocialButton.twitter {
    width: calc(50% - var(--spacing-2x));
}

.success {
    align-items: center;
    margin-bottom: var(--spacing-6x);
}

.success svg {
    color: var(--color-green);
    fill: var(--color-green);
    stroke: var(--color-green);
}

.input-subscribe,
.input-subscribe:focus,
.input-subscribe:active {
    background: var(--color-black);
    border: 0px;
    border-bottom: 2px solid var(--color-white);
    border-radius: 0;
    color: var(--color-white);
    height: 60px;
    margin-bottom: var(--spacing-4x);
    padding: 0;
}

.input-subscribe::placeholder {
    color: var(--color-white);
}

.button-subscribe {
    border: none;
    border-radius: var(--spacing);
    height: 60px;
    outline: none;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .DialogSubscribe {
        background: var(--color-black);
        max-height: min(calc(100vh - 40px), 6s00px);
        min-height: min(90vh, 600px);
        height: 100%;
        max-width: min(85%, 500px);
        padding: var(--spacing-4x);
    }

    .DialogSubscribe .label {
        font-size: 15px;
        text-align: center;
    }

    .DialogSubscribe .supertitle {
        font-size: 24px;
        padding: var(--spacing);
        text-align: center;
    }

    .SocialButton.instagram,
    .SocialButton.twitter {
        margin-bottom: var(--spacing-2x);
        width: calc(100% - var(--spacing-2x));
    }
}
