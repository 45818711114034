.App {
  background: var(--color-black);
  /* background-image: url("./assets/png/logo.png"); */
  /* background-repeat: repeat; */
  text-align: center;
  height: 100vh;
}

.App .container {
  align-items: space-between;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 var(--spacing-4x);
  z-index: 2;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
